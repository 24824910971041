import React from "react";
import ReactDOM from "react-dom/client";

import LandingPage from "./routes/LandingPage";
import Admin from "./routes/Admin";
import Producer from "./routes/Producer";
import ContentBuilder from "./routes/ContentBuilder";

import IpadInterface from "./routes/IpadInterface";

import OverlayManager from "./routes/OverlayManager";
import Decklists from "./routes/Decklists";
import Standings from "./routes/StandingsManager";
import Top8 from "./routes/Top8";
import DatabaseManagement from "./routes/DatabaseManagement";
import FeatureMatchAdminCombined from "./routes/FeatureMatchAdminCombined";
import FeatureMatchAdmin from "./routes/FeatureMatchAdmin";
import TableHistory from "./routes/TableHistory";
import DraftAdmin from "./routes/DraftAdmin";
import PlayerPhotos from "./routes/PlayerPhotos";
import CardsInHandIpad from "./routes/CardsInHandIpad";
import DraftIpad from "./routes/DraftIpad";
import Xpression from "./routes/Xpression";

import HeadToHead from "./routes/HeadToHead";
import HeadToHeadEditor from "./routes/HeadToHeadEditor";
import PlayerProfiles from "./routes/PlayerProfiles";
import PlayerProfileEditor from "./routes/PlayerProfileEditor";
import RoundByRound from "./routes/RoundByRound";
import RoundByRoundEditor from "./routes/RoundByRoundEditor";
import TimerBackground from "./routes/viewers/TimerBackground";

import FeatureMatchOverlay from "./routes/viewers/FeatureMatchOverlay";
import FeatureMatch from "./routes/viewers/FeatureMatch";
import CelebrityDraftViewer from "./routes/viewers/CelebrityDraftViewer";
import DraftViewer from "./routes/viewers/DraftViewer";
import DecklistViewer from "./routes/viewers/DecklistViewer";
import DecklistInteractiveViewer from "./routes/viewers/DecklistInteractiveViewer";
import StandingsViewer from "./routes/viewers/StandingsViewer";
import RoundByRoundViewer from "./routes/viewers/RoundByRoundViewer";
import CustomLeaderboardViewer from "./routes/viewers/CustomLeaderboardViewer";
import FeatureCardsViewer from "./routes/viewers/FeatureCardsViewer";
import LowerThirdsViewer from "./routes/viewers/LowerThirdsViewer";
import HeadToHeadViewer from "./routes/viewers/HeadToHeadViewer";
import PlayerProfileViewer from "./routes/viewers/PlayerProfileViewer";
import Top8Viewer from "./routes/viewers/Top8Viewer";
import LEDBracketViewer from "./routes/viewers/LEDBracketViewer";
import LEDPlayersViewer from "./routes/viewers/LEDPlayersViewer";
import LEDVersusViewer from "./routes/viewers/LEDVersusViewer";
import LEDVersusTop8Viewer from "./routes/viewers/LEDVersusTop8Viewer";
import LEDChampionTop8Viewer from "./routes/viewers/LEDChampionTop8Viewer";

import Top8MovingOnViewer from "./routes/viewers/Top8MovingOnViewer";

import ServerMessage from "./components/layout/ServerMessage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SocketContext, socket } from "./context/socket";

import "./fonts.css";
import "./index.css";
import "./manaIcons.css";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

let theme = createTheme({
   palette: {
      type: "dark",
      text: {
         white: "#fff",
         primary: "#111",
         light: "#b1b1b1",
         medium: "#e0e0e0",
         mediumdark: "#1f1e1e",
         dark: "#7e7e7b",
         orange: "#ff6000",
      },
      alert: {
         main: "#e83411",
      },
      secondary: {
         main: "#555",
      },
      background: {
         light: "#e6e6e6",
         dark: "#111",
         gradientshadow:
            "linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%)",
         scorebartransparency:
            "linear-gradient(90deg, rgba(26,26,26,0) 0%, rgba(26,26,26,1) 6%)",
         gradient:
            "linear-gradient(90deg, rgba(38,37,37,1) 0%, rgba(88,87,88,1) 100%)",
         gradient5050:
            "linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(222,222,222,1) 50%, rgba(222,222,222,1) 100%)",
         gradientDark:
            "linear-gradient(90deg, rgba(29,29,29,1) 0%, rgba(31,31,31,1) 100%)",
      },
   },
});

theme = createTheme(theme, {
   components: {
      MuiTab: {
         styleOverrides: {
            root: {
               "&.MuiButtonBase-root.Mui-selected": {
                  color: theme.palette.text.orange,
               },
            },
         },
      },
      MuiSelect: {
         styleOverrides: {
            root: {
               "&.MuiFilledInput-root:hover": {
                  backgroundColor: "rgba(255,255,255,.7)",
               },
               "&.MuiFilledInput-root.Mui-focused": {
                  backgroundColor: "rgba(255,255,255,.7)",
               },
            },
         },
      },
      MuiTextField: {
         styleOverrides: {
            root: {
               "&.MuiTextField-root": {
                  borderRadius: 1,
               },
               "&.MuiTextField-root .MuiInputBase-root": {
                  backgroundColor: "rgb(255, 255,255, .8)",
               },
               "&.MuiTextField-root .MuiOutlinedInput-root:hover": {
                  backgroundColor: "rgb(255, 237, 237, .8)",
                  "@media (hover: none)": {
                     backgroundColor: "rgb(255, 237, 237, .8)",
                  },
               },
               "&.MuiTextField-root .MuiInputLabel-root": {
                  color: "#999",
                  borderRadius: 3,
                  border: "1px solid rgba(0,0,0,0)",
                  backgroundColor: "rgba(255,255,255, 0)",
                  padding: "0 1em",
                  marginLeft: "-5px",
               },
               "&.MuiTextField-root .MuiInputLabel-shrink": {
                  color: "#aaa",
                  border: "1px solid rgba(0,0,0,.2)",
                  backgroundColor: "rgba(255,255,255, 1)",
               },
            },
         },
      },
      MuiTabs: {
         styleOverrides: {
            root: {
               "& .MuiTabs-indicator": {
                  backgroundColor: "red",
               },
            },
         },
      },
      MuiTab: {
         styleOverrides: {
            root: {
               color: "white",
               "&.Mui-selected": {
                  color: "red",
               },
               "&:hover": {
                  color: "gray",
               },
            },
         },
      },
      MuiTable: {
         variants: [
            {
               props: { variant: "dashboard" },
               style: {
                  background:
                     "linear-gradient(90deg, rgba(38,37,37,1) 0%, rgba(88,87,88,1) 100%)",
                  "& .MuiTableRow-head > .MuiTableCell-root": {
                     fontWeight: "bold",
                     textTransform: "uppercase",
                     color: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     color: "#262525",
                     borderBottom: "none",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                     backgroundColor: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                     backgroundColor: "#dedede",
                  },
               },
            },
            {
               props: { variant: "standings" },
               style: {
                  background:
                     "linear-gradient(90deg, rgba(38,37,37,1) 0%, rgba(88,87,88,1) 100%)",
                  "& .MuiTableRow-head > .MuiTableCell-root": {
                     fontFamily: "GothamNarrow-Medium",
                     fontSize: 20,
                     color: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     fontFamily: "GothamNarrow-Bold",
                     fontSize: 30,
                     color: "#262525",
                     borderBottom: "none",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                     backgroundColor: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                     backgroundColor: "#dedede",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) .fadeAway":
                     {
                        background:
                           "linear-gradient(90deg, rgba(230,230,230,0) 0%, rgba(230,230,230,0) 95%, rgba(230,230,230,1) 100%)",
                     },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even) .fadeAway":
                     {
                        background:
                           "linear-gradient(90deg, rgba(222,222,222,0) 0%, rgba(222,222,222,0) 95%, rgba(222,222,222,1) 100%)",
                     },
               },
            },
            {
               props: { variant: "roundbyround" },
               style: {
                  background:
                     "linear-gradient(90deg, rgba(38,37,37,1) 0%, rgba(88,87,88,1) 100%)",
                  "& .MuiTableRow-head > .MuiTableCell-root": {
                     fontFamily: "GothamNarrow-Medium",
                     fontSize: 20,
                     paddingTop: 8,
                     paddingBottom: 8,
                     color: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     fontFamily: "GothamNarrow-Bold",
                     fontSize: 28,
                     color: "#262525",
                     borderBottom: "none",
                     paddingTop: 1,
                     paddingBottom: 1,
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                     backgroundColor: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                     backgroundColor: "#dedede",
                  },
               },
            },
            {
               props: { variant: "cardinhand" },
               style: {
                  background: "#333",
                  "& .MuiTableRow-head > .MuiTableCell-root": {
                     fontSize: 12,
                     color: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     fontSize: 16,
                     color: "#262525",
                     borderBottom: "none",
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 15,
                     paddingRight: 15,
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                     backgroundColor: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                     backgroundColor: "#dedede",
                  },
               },
            },
            {
               props: { variant: "fmCardsInHand" },
               style: {
                  background: "#333",
                  "& .MuiTableRow-head > .MuiTableCell-root": {
                     fontSize: 10,
                     color: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableCell-root": {
                     fontSize: 12,
                     padding: 2,
                     color: "#262525",
                     borderBottom: "none",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
                     backgroundColor: "#e6e6e6",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)": {
                     backgroundColor: "#dedede",
                  },
               },
            },
         ],
      },
      MuiButton: {
         disableElevation: true,
         styleOverrides: {
            root: {
               fontWeight: "bold",
            },
         },
         variants: [
            {
               props: { variant: "ipadLarge", color: "primary" },
               style: {
                  fontSize: "2em",
                  border: "2px solid #121d26",
                  backgroundColor: "#121d26",
                  color: "#fff",
                  padding: 30,
                  height: "12vh",
                  textAlign: "center",
               },
            },
            {
               props: { variant: "ipadLarge", color: "secondary" },
               style: {
                  fontSize: "2em",
                  border: "2px solid #3e2912",
                  backgroundColor: "#3e2912",
                  color: "#fff",
                  padding: 30,
                  height: "12vh",
                  textAlign: "center",
               },
            },
            {
               props: { variant: "ipadLarge", color: "tertiary" },
               style: {
                  fontSize: "2em",
                  border: "2px solid #223e12",
                  backgroundColor: "#223e12",
                  color: "#fff",
                  padding: 30,
                  height: "12vh",
                  textAlign: "center",
               },
            },
            {
               props: { variant: "ipadLarge", color: "producer" },
               style: {
                  fontSize: "2em",
                  border: "2px solid #33123e",
                  backgroundColor: "#33123e",
                  color: "#fff",
                  padding: 30,
                  height: "12vh",
               },
            },
            {
               props: { variant: "ipadLarge", color: "admin" },
               style: {
                  fontSize: "2em",
                  border: "2px solid #4c0d0d",
                  backgroundColor: "#4c0d0d",
                  color: "#fff",
                  padding: 30,
                  height: "12vh",
                  textAlign: "center",
               },
            },
            {
               props: { variant: "ipadButtonCIH", color: "delete" },
               style: {
                  textTransform: "none",
                  border: "1px solid #5a0000",
                  color: "rgba(255,255,255,.8)",
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "1.5vw",
                  height: "6vh",
                  minHeight: 80,
                  backgroundColor: "#5a0000",
                  "&:hover": {
                     backgroundColor: "#5a0000",
                  },
               },
            },
            {
               props: { variant: "ipadButtonCIH", color: "add" },
               style: {
                  textTransform: "none",
                  border: "1px solid #0a3e12",
                  color: "rgba(255,255,255,.8)",
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "1.5vw",
                  lineHeight: "2.5vw",
                  height: "6vh",
                  minHeight: 80,
                  backgroundColor: "#0a3e12",
                  "&:hover": {
                     backgroundColor: "#0a3e12",
                  },
               },
            },
            {
               props: { variant: "ipadButtonCIH", color: "addSideboard" },
               style: {
                  textTransform: "none",
                  border: "1px solid #3e1f0a",
                  color: "rgba(255,255,255,.8)",
                  fontWeight: "normal",
                  textAlign: "center",
                  fontSize: "2vw",
                  lineHeight: "2.5vw",
                  height: "6vh",
                  minHeight: 80,
                  backgroundColor: "#3e1f0a",
                  "&:hover": {
                     backgroundColor: "#3e1f0a",
                  },
               },
            },
            {
               props: { variant: "ipadButton" },
               style: {
                  border: "1px solid #555",
                  color: "#888",
                  fontWeight: "normal",
                  textAlign: "center",
               },
            },
            {
               props: { variant: "disabled" },
               style: {
                  color: "#333",
                  backgroundColor: "#666",
                  "&:hover": {
                     backgroundColor: "#666",
                  },
               },
            },
         ],
      },
      MuiDataGrid: {
         styleOverrides: {
            root: {
               "&.MuiDataGrid-root": {
                  backgroundColor: "#222",
                  color: "#fff",
                  border: "1px solid #444",
               },
               "&.MuiDataGrid-root .Mui-selected": {
                  backgroundColor: "rgba(255,0,0,.3)",
               },
               "&.MuiDataGrid-root .Mui-selected:hover": {
                  backgroundColor: "rgba(255,0,0,.4)",
               },
               "&.MuiDataGrid-root .MuiDataGrid-cell, &.MuiDataGrid-root .MuiDataGrid-columnHeaders":
                  {
                     borderBottom: "1px solid #444",
                  },
               "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer": {
                  backgroundColor: "#111",
               },
               "&.MuiDataGrid-root .MuiTablePagination-root": {
                  color: "#fff",
               },
               "&.MuiDataGrid-root .MuiSvgIcon-root": {
                  color: "rgba(255,255,255,.5)",
               },
               "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid #444",
               },

               "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                  {
                     outline: "none",
                  },
            },
         },
      },
   },
   typography: {
      h1: {
         fontFamily: "GothamNarrow-Bold",
         padding: 0,
         fontSize: 65,
         color: "#262626",
      },
      h2: {
         fontFamily: "GothamNarrow-Light",
         fontSize: 48,
         color: theme.palette.text.orange,
      },
      h3: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 28,
         color: "#fff",
      },
      h4: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 28,
         color: "#fff",
      },
      h5: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 14,
         paddingTop: 1,
         color: "#fff",
         m: 0,
      },
      h6: {
         fontSize: 12,
         color: "#fff",
      },
      h4top8led: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 22,
         color: "#262626",
         textTransform: "uppercase",
         letterSpacing: "-0.04em",
         lineHeight: 1,
      },

      h4topnamesviewer: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 26,
         color: "#fff",
         textTransform: "uppercase",
         letterSpacing: "-0.04em",
         lineHeight: 1,
      },
      h5top8led: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 16,
         color: "#777",
         textTransform: "uppercase",
         letterSpacing: "-0.04em",
         lineHeight: 1,
      },
      h6top8led: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 30,
         color: "#fff",
         lineHeight: 1,
         paddingTop: 5,
      },
      h4top8: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 31,
         color: "#262626",
         textTransform: "uppercase",
         letterSpacing: "-0.04em",
         lineHeight: 1,
      },
      h5top8: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 18,
         color: "#000000",
         textTransform: "uppercase",
         letterSpacing: "-0.04em",
         lineHeight: 1,
      },
      h6top8: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 36,
         color: "#fff",
         lineHeight: 1,
         paddingTop: 5,
      },
      h5header: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 14,
         paddingTop: 1,
         color: "#fff",
         marginBottom: 0,
      },
      h1Viewer: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 65,
         lineHeight: 1.167,
         letterSpacing: "-0.01562em",
         color: theme.palette.text.white,
         fontWeight: 300,
         margin: 0,
         padding: 0,
         textTransform: "uppercase",
      },
      h2Viewer: {
         fontFamily: "GothamNarrow-Light",
         fontSize: 48,
         color: theme.palette.text.orange,
         margin: 0,
         fontWeight: 300,
         lineHeight: 1.2,
         letterSpacing: "-0.00833em",
         textTransform: "uppercase",
      },
      h2vsViewer: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 48,
         color: theme.palette.text.white,
         margin: 0,
         fontWeight: 300,
         lineHeight: 1.2,
         letterSpacing: "-0.00833em",
         textTransform: "uppercase",
      },
      h3LEDViewer: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 50,
         color: "#fff",
      },
      h3Viewer: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 70,
         color: "#fff",
      },
      h4Viewer: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 32,
         color: "#fff",
      },
      h5Viewer: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 20,
         color: "#fff",
      },
      h6Viewer: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 26,
         color: theme.palette.text.light,
         textTransform: "uppercase",
      },
      h5DecklistNoImage: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 12,
         color: theme.palette.text.white,
         letterSpacing: 0,
      },
      h5DecklistQty: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 20,
         lineHeight: 1.8,
         color: theme.palette.text.white,
         backgroundColor: theme.palette.text.mediumdark,
         width: 33,
         height: 33,
      },
      h1headtohead: {
         fontFamily: "GothamNarrow-Light",
         padding: 0,
         fontSize: 65,
         lineHeight: 1.5,
         textAlign: "center",
         color: "#faf9f9",
      },
      h1headtoheadplayer: {
         fontFamily: "GothamNarrow-Bold",
         padding: 0,
         fontSize: 45,
         lineHeight: 1,
         color: "#f4f4f4",
      },
      h2headtoheadplayer: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 24,
         color: "#7e7e7e",
      },
      h1head2headvalue: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 55,
         lineHeight: 1.1,
         color: "#262626",
      },
      h2head2headtitle: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 28,
         lineHeight: 1,
         color: "#767676",
      },
      h4head2headdecklist: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 30,
         lineHeight: 1,
         color: "#262626",
      },
      h1profile: {
         fontFamily: "GothamNarrow-Bold",
         padding: 0,
         fontSize: 88,
         lineHeight: 0.9,
         color: "#262626",
      },
      h2profile: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 36,
         color: "#767676",
      },
      h3profile: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 28,
         color: "#fff",
      },
      h4profiledecklist: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 30,
         lineHeight: 1,
         color: "#fff",
      },
      h1profilevalue: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 65,
         lineHeight: 1,
         color: "#262626",
      },
      h2profiletitle: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 32,
         lineHeight: 1.1,
         color: "#767676",
      },
      fmStandings: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 19,
         paddingTop: 1,
         color: theme.palette.text.light,
      },
      fmRoundTitle: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 28,
         color: theme.palette.text.medium,
      },
      fmPoison: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 45,
         color: theme.palette.text.white,
      },
      fmPlayerName: {
         fontFamily: "GothamNarrow-Bold",
         fontSize: 32,
         lineHeight: 1,
         color: theme.palette.text.white,
      },
      fmPlayerPronoun: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 14,
         letterSpacing: 2,
         color: theme.palette.text.light,
      },
      fmPlayerDeck: {
         fontFamily: "GothamNarrow-Medium",
         fontSize: 18,
         letterSpacing: 2,
         paddingTop: 1,
         color: theme.palette.text.light,
      },
      fmOpeningHand: {
         fontFamily: "GothamNarrow-Book",
         fontSize: 18,
         paddingTop: 1,
         color: theme.palette.text.light,
         "& b": {
            fontFamily: "GothamNarrow-Medium",
            color: theme.palette.text.orange,
         },
      },
      fmLifeTotal: {
         fontFamily: "GothamNarrow-Bold",
         padding: 0,
         fontSize: 68,
         color: theme.palette.text.white,
      },
   },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <>
      <SocketContext.Provider value={socket}>
         <ThemeProvider theme={theme}>
            <CssBaseline />

            <Router>
               <Routes>
                  {/* ROUTES */}
                  {/* 
                  <Route path='/cardlibrary' element={<CardLibrary />} />
                  <Route path='/decklists' element={<Decklists />} />
                  <Route path='/standings' element={<Standings />} />
                  <Route path='/bracket' element={<Top8 />} />
                  <Route path='/playerphotos' element={<PlayerPhotos />} />

                  <Route path='/headtohead' element={<HeadToHead />} />
                  <Route
                     path='/headtohead/:id'
                     element={<HeadToHeadEditor />}
                  />

                  <Route path='/playerprofiles' element={<PlayerProfiles />} />
                  <Route
                     path='/playerprofile/:id'
                     element={<PlayerProfileEditor />}
                  />

                  <Route path='/roundbyround' element={<RoundByRound />} />
                  <Route
                     path='/RoundByRound/:id'
                     element={<RoundByRoundEditor />}
                  /> */}

                  <Route
                     path='/overlaymanager'
                     element={
                        <ServerMessage>
                           <OverlayManager />
                        </ServerMessage>
                     }
                  />

                  <Route
                     path='/cardsinhand/:tableId/:playerPosition'
                     element={
                        <ServerMessage>
                           <CardsInHandIpad />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/featurematches'
                     element={
                        <ServerMessage>
                           <FeatureMatchAdminCombined />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/featurematch/:tableId'
                     element={
                        <ServerMessage>
                           <FeatureMatchAdmin />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/tablehistory'
                     element={
                        <ServerMessage>
                           <TableHistory />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/draft/:draftId'
                     element={
                        <ServerMessage>
                           <DraftAdmin />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/draftipad/:draftId'
                     element={
                        <ServerMessage>
                           <DraftIpad />
                        </ServerMessage>
                     }
                  />

                  {/* VIEWERS */}
                  <Route
                     path='/viewer/featurematchoverlay'
                     element={<FeatureMatchOverlay />}
                  />
                  <Route
                     path='/viewer/ledversus'
                     element={<LEDVersusViewer />}
                  />
                  <Route
                     path='/viewer/ledversustop8'
                     element={<LEDVersusTop8Viewer />}
                  />
                  <Route
                     path='/viewer/ledchampiontop8'
                     element={<LEDChampionTop8Viewer />}
                  />
                  <Route
                     path='/viewer/ledplayers'
                     element={<LEDPlayersViewer />}
                  />
                  <Route
                     path='/viewer/ledbracket'
                     element={<LEDBracketViewer />}
                  />
                  <Route path='/viewer/bracket' element={<Top8Viewer />} />
                  <Route
                     path='/viewer/featurematch/:id'
                     element={<FeatureMatch />}
                  />
                  <Route
                     path='/viewer/draft/:draftId'
                     element={<DraftViewer />}
                  />
                  <Route
                     path='/viewer/celebritydraft'
                     element={<CelebrityDraftViewer />}
                  />

                  <Route
                     path='/viewer/decklist/:viewerId'
                     element={<DecklistViewer />}
                  />
                  <Route
                     path='/viewer/decklistinteractive'
                     element={<DecklistInteractiveViewer />}
                  />

                  <Route
                     path='/viewer/standings'
                     element={<StandingsViewer />}
                  />

                  <Route
                     path='/viewer/roundbyround'
                     element={<RoundByRoundViewer />}
                  />

                  <Route
                     path='/viewer/customleaderboard/:viewerId'
                     element={<CustomLeaderboardViewer />}
                  />

                  <Route
                     path='/viewer/featurecards'
                     element={<FeatureCardsViewer />}
                  />

                  <Route
                     path='/viewer/lowerthirds/:output'
                     element={<LowerThirdsViewer />}
                  />

                  <Route
                     path='/viewer/headtohead/:viewerId'
                     element={<HeadToHeadViewer />}
                  />

                  <Route path='/viewer/top8' element={<Top8MovingOnViewer />} />

                  <Route
                     path='/viewer/profile/:output'
                     element={<PlayerProfileViewer />}
                  />

                  <Route path='/viewer/timer' element={<TimerBackground />} />

                  {/* DASHBOARD PAGES */}
                  <Route
                     path='/ipad/:tableId'
                     element={
                        <ServerMessage>
                           <IpadInterface />
                        </ServerMessage>
                     }
                  />

                  <Route
                     path='/admin/*'
                     element={
                        <ServerMessage>
                           <Admin />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/producer/*'
                     element={
                        <ServerMessage>
                           <Producer />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/contentbuilder/*'
                     element={
                        <ServerMessage>
                           <ContentBuilder />
                        </ServerMessage>
                     }
                  />
                  <Route
                     path='/'
                     element={
                        <ServerMessage>
                           <LandingPage />
                        </ServerMessage>
                     }
                  />

                  <Route
                     path='/database'
                     element={
                        <ServerMessage>
                           <DatabaseManagement />
                        </ServerMessage>
                     }
                  />

                  <Route
                     path='/xpression'
                     element={
                        <ServerMessage>
                           <Xpression />
                        </ServerMessage>
                     }
                  />
               </Routes>
            </Router>
         </ThemeProvider>
      </SocketContext.Provider>
   </>
);
